@charset "utf-8";
@import "compass/compass";
@import "mixin";
@import "animate";

/*リセット
--------------------------------------------*/
@include global-reset;

/*フォント
---------------------------------------------*/

/*メディアクエリー
---------------------------------------------*/
$breakpoints: (
  'xs': 'screen and (min-width: 400px)',
  'sp': 'screen and (max-width: 768px)',
  'pc': 'screen and (min-width: 769px)',
  'xl': 'screen and (min-width: 1200px)',
) !default;

/*変数
---------------------------------------------*/
$pcFontXs: 10px;
$pcFontS: 12px;
$pcFontM: 14px;
$pcFontL: 22px;
$pcSpace1: 19px;
$pcSpace2: 38px;
$pcSpace3: 57px;
$red: #d45657;
$spFontS: 10px;
$spFontM: 14px;
$spFontL: 16px;

/* コンテンツ
---------------------------------------------*/
html, body{
  font-family: 'Helvetica Neue' , sans-serif;
  line-height: 1.6;
  width: 100%;
  height: 100%;
  color: #000;
  // overflow-x: hidden;
}
img{
  width: 100%;
  display: block;
}

/*----------- TOPページ -----------*/
div#topContainer{
  // overflow: auto;
  // scroll-snap-type: y mandatory;
  // height: 100vh;
  // height: calc(var(--vh, 1vh) * 100);
  height: 100%;
  div.content{
    // scroll-snap-align: start;
    width: 100%;
    height: 100%;
    // height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 78px 18px 78px;
    div.inner{
      width :100%;
      height: 100%;
      background: #ccc;
      position: relative;
      box-sizing: border-box;

      h2{
        position: absolute;
        width: 20%;
        top: 12px;
        left: 5px;
      }
    }

  }
  div#contents01{
    background: #4BB953;
    div.inner{
      background: url(../images/front/topImg01.jpg);
      background-size: cover;
      background-position: center center;
      h2 {
        height: 100%;
        img {
          width: auto;
          height: 65%;
        }
      }
    }
  }
  div#contents02{
    background: #F5CD31;
    div.inner{
      background: url(../images/front/topImg02.jpg);
      background-size: cover;
      background-position: center center;
      h2{
        height: 100%;
        left: -6px;
        img{
          width: auto;
          height: 80%;
        }
      }
    }
  }
  div#contents03{
    background: #0099D1;
    div.inner{
      background: url(../images/front/topImg03.jpg);
      background-size: cover;
      background-position: center center;
      h2{
        height: 100%;
        left: -6px;
        img {
          width: auto;
          height: 92%;
          position: absolute;
          bottom: 20px;
        }
      }
    }
  }
  div#contents04 {
    background: #E84252;
    div.inner {
      background: url(../images/front/topImg04.jpg);
      background-size: cover;
      background-position: center center;
      h2 {
        height: 100%;
        img {
          width: auto;
          height: 60%;
          position: absolute;
          left: -12px;
        }
      }
    }
  }
  div#contents05 {
    background: #3BD0AE;
    div.inner{
      background: url(../images/front/topImg05.jpg);
      background-size: cover;
      background-position: center center;
      h2 {
        height: 100%;
        img {
          width: auto;
          height: 80%;
          position: absolute;
          bottom: 20px;
          left: -12px;
        }
      }
    }
  }
  div#contents06 {
    background: #c2e403;
    div.inner{
      background: url(../images/front/topImg06.jpg);
      background-size: cover;
      background-position: center center;
      h2 {
        height: 100%;
        img {
          width: auto;
          height: 85%;
          position: absolute;
          bottom: 20px;
          left: 2px;
        }
      }
    }
  }
  div#contents07 {
    background: #9725EA;

    div.inner {
      background: url(../images/front/topImg07.jpg);
      background-size: cover;
      background-position: center center;

      h2 {
        height: 100%;
        img {
          width: auto;
          height: 80%;
          position: absolute;
          bottom: 20px;
          left: -12px;
        }
      }
    }
  }
  }


  /*----------- WHAT'S REPRO -----------*/
div#whatsContainer{
  div.main{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  h2{
    font-size: 22px;
    text-align :center;
    margin-bottom: 100px;

    span{
      font-size: 60px;
      font-weight: bold;
    }
  }
  h3{
    font-size: 18px;
    letter-spacing: 10px;
    font-weight: bold;
    text-align :center;

    padding: 0 5px 5px;

    &.line{
      @include marker(#FFEB00);
      background-size: 0%;
      background-repeat: no-repeat;
      -webkit-animation: drawTheLine .5s linear 1 forwards;
      animation: drawTheLine .5s linear 1 forwards;
    }
  }
  div.about{
    height: 100%;
    position: relative;
    &::before{
      content: "";
      display: block;
      width: 40px;
      background: #000;
      height: 0;
      position :absolute;
      left: 5%;
      top: -150px;
    }
    div.repro{
      background: #000;
      box-sizing: border-box;
      padding: 70px 0;
      margin-bottom: 150px;
      div.inner{
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      h4{
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        font-size: 18px;
        width: 10%;
        color: #fff;
        font-weight :bold;
        position: relative;
        z-index: 9;

      }
      p{
        color: #fff;
        width: 90%;
        text-align: center;
        font-size: 13px;
        span.base{
          display: inline-block;
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          padding-left: 20px;
        }
        span.textyellow{
          color: #ffeb00;
          font-weight: bold;
        }
        span.yellow{
          background: linear-gradient(transparent 0%, #ffeb00 0%);
          color: #000;
          font-weight: bold;
          background-size: 0;
          background-repeat: no-repeat;

        }
        span.yellow.line{
          -webkit-animation: drawTheLine .5s linear 1 forwards;
          animation: drawTheLine .5s linear 1 forwards;
        }
        span.right{
          display: inline-block;
          text-align: right;
          width: 100%;
        }
      }
      a{
        color:#ffeb00;
        font-weight: bold;
        font-size: 15px;
        text-decoration: none;
      }
    }
    div.baseyard, div.curator{
      position: relative;
      z-index: 2;
      margin-bottom: 150px;
      div.img{
        margin-bottom: 30px;
        position : relative;
        p{
          position: absolute;
          font-size: 35px;
          top: 50%;
          left: 50%;
          transform: translate(-50%,-50%);
          color: #fff;
        }
      }
      div.inner{
        width: 90%;
        margin: 0 auto;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
      }
      h4{
        -webkit-writing-mode: vertical-rl;
        -ms-writing-mode: tb-rl;
        writing-mode: vertical-rl;
        font-size: 18px;
        width: 10%;
        color: #fff;
        font-weight :bold;
        position: relative;
        z-index: 9;

      }
      p{
        color: #000;
        width: 90%;
        text-align: center;
        font-size: 13px;
        span{
          display: inline-block;
          width: 100%;
          text-align: left;
          box-sizing: border-box;
          padding-left: 20px;
        }
      }
    }
  }

  div.about.vertical-line{
    &::before{
      animation: vertical-line-high 2s ease-in alternate .6s forwards;
    }
  }

}


/* ----------- PRODUCT ------------ */
div#productContainer{
  box-sizing: border-box;
  padding: 60px 0 120px;
  position: relative;
  // height: 100%;
  &::before{
    content: "";
    display: block;
    width: 40px;
    background: #000;
    height: 0%;
    position :absolute;
    left: 5%;
    top: 0px;
    animation: vertical-line 1.2s ease-in alternate forwards;
  }
  h2{
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: 18px;
   left: 7%;
   top: 50%;
   transform: translateY(-50%);
    color: #fff;
    font-weight :bold;
    position: fixed;
    z-index: 9;
  }
  div.product{
    margin: 40px 0 0 0;
    padding: 0 10% 0 calc(10% + 40px);
    box-sizing: border-box;
    position: relative;
    .img {
      img {
            border: 5px solid #000;
            box-sizing: border-box;
      }
    }

    p{
 text-align: center;
 font-size: 20px;
 border-left: 5px solid #000;
 border-right: 5px solid;
 border-bottom: 5px solid;
 font-family: 'Teko';
 font-weight: bold;
 padding: 3px 0 0;
 line-height: 25px;
 letter-spacing: 0.01rem;
 width: 100%;
 box-sizing: border-box;
    }
    p.buy_btn {
    position: absolute;
    border: solid 5px #000;
    right: 9%;
    top: -10px;
    width: auto;
    height: 110px;
    padding: 4% 2%;
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    background: #FFEB00;
    font-style: italic;
    }
    span{
      color: #0099D1;
    }
    a{
      color: #000;
      text-decoration: none;
    }
  }


}

/*----------- PRODUCT01 BMX -----------*/
div#product01Container{
  width: 100%;
  overflow: hidden;
  div.main{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product01/main.jpg);
    background-position: center center;
    background-size: cover;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
      width: 85%;
      margin: auto;
    }
    div.arrow{
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }
  div.content{
    div.outline{
      position: relative;

      div.img{
        box-sizing: border-box;
        border: #4BB953 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }
      div.explain{
        position: relative;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 100px;
        padding-bottom: 50px;
        div.outline_logo{
          width: 28%;
          transform: translateX(-50%);
        }
        p{
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
        div.outline_logo.left{
          animation: left 1.2s ease-in alternate forwards;
        }
      }
      div.point{
        position: absolute;
        bottom: 0;
        right: 5%;
        width: 60%;
          path{
             stroke:#4BB953;
             stroke-width: 2px;
            stroke-dasharray: 3000;
            stroke-dashoffset:3000;

          }

      }
      div.point.drawingText{
        path{
          -webkit-animation:DASH 3s ease-in alternate forwards;
            -o-animation:DASH 3s ease-in alternate forwards;
            animation:DASH 3s ease-in alternate forwards;
        }
      }
      &::before{
        content: "";
        display: block;
        width: 40px;
        background: #4BB953;
        height: 0%;
        position :absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line-160 1.2s ease-in alternate 2s forwards;
      }
    }
    div.etc{
      position: relative;
      z-index: 2;
      top: -100px;
      margin-bottom: -100px;
      .otherImg1{
        margin-bottom: 40px;
      }
      .movie{
        width: 100%;
        margin: 0 auto 100px;
        position: relative;
        div{
          width: 90%;
          margin: 0 auto;
          video {
                border: solid 5px;
                width: 100%;
                box-sizing: border-box;
          }
        }
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 60px;
          background: #4BB953;
          position: absolute;
          bottom: -30px;
          z-index: -1;

        }
      }
      .otherImg2{
        width: 100%;
        margin: 0 auto 100px;
        position: relative;
        img{
          width: 90%;
          margin: 0 auto;
        }
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 60px;
          background: #4BB953;
          position: absolute;
          bottom: -30px;
          z-index: -1;

        }
      }
    }
    div.curater{
      width: 100%;
      position: relative;
      margin-bottom: 100px;
      &::before{
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product01/voice.jpg);
        background-size: cover;
        background-position: center center;

      }
      div.inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;
        div.box1{
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            width: 67%;
          }
          &::before{
            content: "";
            display: block;

            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: -2;
          }
        }
        div.box2{
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        p {
          font-size: 14px;
          color: #fff;
          width: 90%;
          margin: 0 auto;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 6px #000;
          font-weight: 500;
        }
        }
        div.box1.active{
          &::before{
            opacity:0.7;
            background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, #4BB953 0%);
            background: linear-gradient(rgba(255,255,255,0) 0%, #4BB953 0%);
          background-repeat: no-repeat;
          background-size: 0%;
          -webkit-animation: drawTheLine 1s linear 1 forwards;
          animation: drawTheLine 1s linear 1 forwards;
          }
        }

      }
    }
    div.detail{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #4BB953;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }
        h3.down{
          animation: down 1s linear forwards;
        }
      }
      div.slider-for{
        width: 85%;
        margin: 20px auto;
        img{
          box-sizing: border-box;
          border: 10px solid #4BB953;
          border-top: none;
        }
        p{
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }
      div.slider-nav{

        img{
          box-sizing: border-box;
          padding: 0 5px;
        }
      }
      .slick-list{
        position: relative;
        &::before{
          content: "";
          display: block;
          width: 100%;
          height: 30px;
          background: #4BB953;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    div.collabo{
      margin-bottom: 100px;
      position: relative;
      &::before{
        content: "";
        display: block;
        background: url(../images/product/product01/collabo.jpg);
        background-size: cover;
        background-position: center center;
        padding-top: 100%;
      }
      div.inner{
        position: absolute;
        top: 40%;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        h3{
          width: 70%;
          margin-left: auto;
          padding-bottom: 10px;
        }
        p{
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 6px #000;
          font-weight: 500;
          line-height: 20px;
          }
      }
    }
    div.other{
    margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #4BB953;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 90%;
          margin: auto;
        }
      }
      a{
        text-decoration: none;
      }
      div.img{
        width: 85%;
        margin: 20px auto 100px;
        position: relative;
        img{
          box-sizing: border-box;
          border: 10px solid #4BB953;
          border-top: none;
        }
      span.name {
    font-family: 'Teko';
    position: absolute;
    width: 70%;
    height: 20%;
    text-align: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    font-size: 40px;
    font-weight: bold;
    margin: auto;
    color: #ffffff9c;
      }
      }
    }

  }
  div.cheer{
    width: 90%;
    margin: 00px auto 00px;
    padding: 50px 0 150px;
    a{
      color: #000;
      display: flex;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }
      p{
        width :60%;
        letter-spacing: 0.05rem;
        box-sizing: border-box;
        padding-left: 20px;
        font-size: 13px;
        position: relative;
        font-weight: bold;
        &::before{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: top left;

        }
        &::after{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          left: 10px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: bottom left;

        }
      }
    }
  }

}

/*----------- PRODUCT02 BONDAGE PANTS -----------*/
div#product02Container{
  width: 100%;
  overflow: hidden;
  div.main{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product02/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;
    h2{
      width: 85%;
      margin: auto;
      position: absolute;
      top: 75px;
      left: 20px;
    }
    div.arrow{
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }
  div.content{
    div.outline{
      position: relative;

      div.img{
        box-sizing: border-box;
        border: #F5CD31 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }
      div.explain{
        position: relative;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 100px;
        padding-bottom: 120px;
        div.outline_logo{
          width: 28%;
          transform: translateX(-50%);
        }

        p{
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
        div.outline_logo.left{
          animation: left 1.2s ease-in alternate forwards;
        }
      }
      div.point{
        position: absolute;
        bottom: 0;
        right: 5%;
        width: 60%;
          path{
             stroke:#F5CD31;
             stroke-width: 2px;
            stroke-dasharray: 3000;
            stroke-dashoffset:3000;

          }

      }
      div.point.drawingText{
        path{
          -webkit-animation:DASH 3s ease-in alternate forwards;
            -o-animation:DASH 3s ease-in alternate forwards;
            animation:DASH 3s ease-in alternate forwards;
        }
      }
      &::before{
        content: "";
        display: block;
        width: 40px;
        background: #F5CD31;
        height: 0%;
        position :absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line-120 1.2s ease-in alternate 2s forwards;
      }
    }
    div.etc{
      position: relative;
      z-index: 1;
      top: -120px;
      margin-bottom: -120px;
      .otherImg1{
        margin-bottom: 80px;
        width: 100%;
        position: relative;
        img{
          width: 95%;
        }
        &::after{
          content: "";
          display: block;
          background: #F5CD31;
          width: 50%;
          height: 150px;
          position: absolute;
          right: 0;
          bottom: -20px;
          z-index: -1;
        }
      }
      .mypattern{
        img{
          box-sizing: border-box;
          padding: 5px;
        }
        margin-bottom: 40px;
      }
      .mypattern .slick-slide:not(.slick-center) {
        -webkit-filter: blur(2px);
        -moz-filter:blur(2);
        -o-filter: blur(2);
        -ms-filter: blur(2);
        filter: blur(2);
        transition: 0.2s linear;
      }
      .otherImg2{
        width: 100%;
        margin: 0 auto 100px;
        position: relative;
        img{
          width: 67%;
          margin: 0 auto;
        }
        &::after{
          content: 'BONDAGE PANTS';
          display: block;
          position: absolute;
          top: 50%;
          // transform: translateY(-50%);
          animation: text-scroll01 2.5s linear alternate infinite;
          z-index: -1;
          font-weight: bold;
          font-size: 75px;
          text-align: center;
          white-space: nowrap;
        }
      }
      .otherImg3{
        width: 100%;
        box-sizing: border-box;
        padding: 50px;
        background: #F5CD31;
        margin-bottom: 100px;
      }
    }
    div.curater{
      width: 100%;
      position: relative;
      margin-bottom: 100px;
      &::before{
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product02/voice_cover.jpg);
        background-size: center center;
        background-position: cover;


      }
      div.inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;
        div.box1{
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            width: 67%;
          }
          &::before{
            content: "";
            display: block;
            // background: rgba(245,209,49,0.7);
            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: -2;
          }
        }
        div.box2{
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        p{
          font-size: 14px;
          color: #fff;
          width: 90%;
          margin: 0 auto;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 8px #000;
          font-weight: 500;
        }
        }
        div.box1.active{
          &::before{
            opacity:0.7;
            background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, #F5CD31 0%);
            background: linear-gradient(rgba(255,255,255,0) 0%, #F5CD31 0%);
          background-repeat: no-repeat;
          background-size: 0%;
          -webkit-animation: drawTheLine 1s linear 1 forwards;
          animation: drawTheLine 1s linear 1 forwards;
          }
        }
      }
    }
    div.detail{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #F5CD31;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }
        h3.down{
          animation: down 1s linear forwards;
        }
      }
      div.slider-for{
        width: 85%;
        margin: 20px auto;
        img{
          box-sizing: border-box;
          border: 10px solid #F5CD31;
          border-top: none;
        }
        p{
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }
      div.slider-nav{

        img{
          box-sizing: border-box;
          padding: 0 5px;
        }
      }
      .slick-list{
        position: relative;
        &::before{
          content: "";
          display: block;
          width: 100%;
          height: 30px;
          background: #F5CD31;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
    div.collabo{
      margin-bottom: 100px;
      position: relative;
      &::before{
        content: "";
        display: block;
        background: url(../images/product/product02/collabo_cover.jpg);
        background-position: center center;
        background-size: cover;
        padding-top: 100%;
      }
      div.inner{
        position: absolute;
    top: 15%;
    left: 50%;
    width: 90%;
    transform: translateX(-50%);
    background: rgba(0, 0, 0, 0.2);
    padding: 20px;
    box-sizing: border-box;
        h3{
          width: 70%;
          margin-right: auto;
          padding-bottom: 10px;
        }
        p{
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 8px #000;
          font-weight: 500;
        }
      }
    }
    div.other{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #F5CD31;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 70%;
          margin: auto;
        }
      }
      div.img{
        width: 85%;
        margin: 20px auto;
        img{
          box-sizing: border-box;
          border: 4px solid #F5CD31;
          border-top: none;
        }
      }
    }
  }
  div.cheer{
    width: 90%;
    margin: 150px auto 200px;
    a{
      color: #000;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }
      p{
        width :60%;
        letter-spacing: 0.05rem;
        box-sizing: border-box;
        padding-right: 20px;
        font-size: 13px;
        position: relative;
        font-weight: bold;
        text-align: right;
        &::before{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 0px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: top right;

        }
        &::after{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: bottom right;

        }
      }
    }
  }
}

/*----------- PRODUCT03 Tshirts -----------*/
div#product03Container{
  width: 100%;
  overflow: hidden;
  div.main{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product03/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;
    h2{
      width: 85%;
      margin: auto;
      position: absolute;
      top: 25%;
      left: 20px;
    }
    div.arrow{
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }
  div.content{
    div.outline{
      position: relative;

      div.img{
        box-sizing: border-box;
        border: #0099D1 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }
      div.explain{
        margin-bottom: 100px;
        padding-bottom: 100px;
        div.flex{
          position: relative;
          z-index: 2;
          width: 100%;
          box-sizing: border-box;
          padding: 0 5% 0 10%;
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin-bottom: 50px;

        }
        div.explainImg{
          position: relative;
          z-index: 2;
          img{
            width: 90%;
            margin: 0 auto;
          }
          &::after{
            content: "";
            display: block;
            width: 100%;
            height: 200px;
            background: #0099D1;
            position: absolute;
            top: 50%;
            z-index: -1;
            transform: translateY(-50%);
          }
        }
        div.outline_logo{
          width: 28%;
          transform: translateX(-50%);
        }
        p{
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
        div.outline_logo.left{
          animation: left 1.2s ease-in alternate forwards;
        }
      }

      div.point{
        position: absolute;
        bottom: 0;
        left: 5%;
        width: 60%;
        z-index: 3;
          path{
             stroke:#0099D1;
             stroke-width: 2px;
            stroke-dasharray: 3000;
            stroke-dashoffset:3000;

          }

      }
      div.point.drawingText{
        path{
          -webkit-animation:DASH 3s ease-in alternate forwards;
            -o-animation:DASH 3s ease-in alternate forwards;
            animation:DASH 3s ease-in alternate forwards;
        }
      }
      &::before{
        content: "";
        display: block;
        width: 40px;
        background: #0099D1;
        height: 0%;
        position :absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line 1.2s ease-in alternate 2s forwards;
      }
    }
    div.etc{
      position: relative;
      z-index: 2;
      top: -80px;
      margin-bottom: -100px;
      .otherImg1{
        margin-bottom: 80px;
        // width: 100%;
        position: relative;
        img{
          margin: auto;
          width: 80%;
        }
        &::after{
          content: "";
          display: block;
          width: 100%;
          background: #0099D1;
          height: 70px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%) scale(0);
          z-index: -1;

        }

      }
      .scale{
        &::after{
          animation: scale03 1s linear alternate forwards;
        }
      }

      .mypattern{
        img{
          box-sizing: border-box;
          padding: 5px;
        }

        margin-bottom: 40px;
      }
      .mypattern .slick-slide:not(.slick-center) {
        -webkit-filter: blur(2px);
        -moz-filter:blur(2);
        -o-filter: blur(2);
        -ms-filter: blur(2);
        filter: blur(2);
        transition: 0.2s linear;
      }
      .otherImg2{
        width: 100%;
        box-sizing: border-box;
        padding: 40px;
        background: #0099D1;
        margin-bottom: 100px;
        position: relative;
        &::before{
          content: "Do The Right Thing T-shirt";
          display: block;
          position: absolute;
          top: -30px;
          left: -30px;
          z-index: 2;
          font-size: 75px;
          font-weight: bold;
          // text-align: center;
          white-space: nowrap;
          animation: text-scroll02 2.5s linear alternate infinite;
          // transform: translateY(-25%);
        }
      }
    }
    div.curater{
      width: 100%;
      position: relative;
      margin-bottom: 100px;
      &::before{
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product03/voice.jpg);
        background-size: cover;
        background-position: center center;
      }
      div.inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;
        div.box1{
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
          h3{
            width: 67%;
          }
          &::before{
            content: "";
            display: block;
            // background: rgba(0,153,209,0.7);
            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: -2;
          }
        }
        div.box2{
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        p{
          font-size: 14px;
          color: #fff;
          width: 90%;
          margin: 0 auto;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 8px #000;
          font-weight: 500;
        }
        }
        div.box1.active{
          &::before{
            opacity:0.7;
            background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, #0099D1 0%);
            background: linear-gradient(rgba(255,255,255,0) 0%, #0099D1 0%);
          background-repeat: no-repeat;
          background-size: 0%;
          -webkit-animation: drawTheLine 1s linear 1 forwards;
          animation: drawTheLine 1s linear 1 forwards;
          }
        }
      }
    }
    div.detail{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #0099D1;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }
        h3.down{
          animation: down 1s linear forwards;
        }
      }
      div.img{
        width: 85%;
        margin: 20px auto;
        img{
          box-sizing: border-box;
          border: 10px solid #0099D1;
          border-top: none;
        }
        p{
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }


    }
    div.collabo{
      margin-bottom: 50px;
      position: relative;
      &::before{
        content: "";
        display: block;
        background: #000;
        padding-top: 100%;
        // background: url(../images/product/product03/img_11.jpg);
        // background-position: center center;
        // background-size: cover;
      }
      div.inner{
        position: absolute;
        top: 10%;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        h3{
          width: 95%;
          margin-right: auto;
          padding-bottom: 10px;
        }
        p{
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.08rem;
          font-weight: 500;
        }
      }
    }
    div.other{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #F5CD31;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 90%;
          margin: auto;
        }
      }
      div.img{
        width: 85%;
        margin: 20px auto;
        img{
          box-sizing: border-box;
          border: 4px solid #F5CD31;
          border-top: none;
        }
      }
    }
    div.profile{
          letter-spacing: 0.08rem;
          font-weight: 500;
      div.first{
        border: 2px solid #000;
        border-radius: 50%;
        width: 180px;
        height: 180px;
        margin: 0 auto;
        background: #000;
        color: #F5CD31;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        // background: url(../images/product/product03/img_12.jpg);
        // background-size: cover;
        // background-position: center center;
      }
      p{
        font-size: 14px;
        width: 90%;
        margin: 10px auto 100px;
        span{
          display: block;
        }
      }
    }
    div.cheer{
      width: 90%;
      margin: 150px auto 200px;
      a{
        color: #000;
        display: flex;
        text-decoration: none;
        align-items: center;
        justify-content: center;
      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }
        p{
          width :60%;
          letter-spacing: 0.05rem;
          box-sizing: border-box;
          padding-left: 20px;
          font-size: 13px;
          position: relative;
          font-weight: bold;
          &::before{
            content : "";
            width: 95%;
            display: block;
            height: 5px;
            position: absolute;
            top: -10px;
            left: 10px;
            background: #000;
            transform: rotate(-20deg);
            transform-origin: top left;

          }
          &::after{
            content : "";
            width: 95%;
            display: block;
            height: 5px;
            position: absolute;
            bottom: -10px;
            left: 10px;
            background: #000;
            transform: rotate(20deg);
            transform-origin: bottom left;

          }
        }
      }
    }
  }
}


/*----------- PRODUCT04 BMX GRIP -----------*/
div#product04Container{
  width: 100%;
  overflow: hidden;
  div.main{
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product04/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;

    h2{
      width: 85%;
      margin: auto;
      position: absolute;
      top: 25%;
      left: 20px;
    }
    div.arrow{
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }
  div.content{
    div.outline{
      position: relative;

      div.img{
        box-sizing: border-box;
        border: #E84252 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }
      div.explain{
        position: relative;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 100px;
        padding-bottom: 120px;
        div.outline_logo{
          width: 28%;
          transform: translateX(-50%);
        }
        div.outline_logo.left{
          animation: left 1.2s ease-in alternate forwards;
        }
        p{
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
      }
      div.point{
        position: absolute;
        bottom: 0;
        right: 5%;
        width: 60%;
          path{
             stroke:#E84252;
             stroke-width: 2px;
            stroke-dasharray: 3000;
            stroke-dashoffset:3000;

          }

      }
      div.point.drawingText{
        path{
          -webkit-animation:DASH 3s ease-in alternate forwards;
            -o-animation:DASH 3s ease-in alternate forwards;
            animation:DASH 3s ease-in alternate forwards;
        }
      }
      &::before{
        content: "";
        display: block;
        width: 40px;
        background: #E84252;
        height: 0%;
        position :absolute;
        left: 5%;

        top: -210px;
        animation: vertical-line-130 1.2s ease-in alternate 2s forwards;
      }
    }
    div.etc{
      position: relative;
      top: -130px;
      margin-bottom: -130px;
      .otherImg1{
        margin-bottom: 100px;
        width: 100%;
        position: relative;
        img{
          width: 75%;
        }
        &::after{
          content: "";
          position: absolute;
          bottom: -30px;;
          z-index: -1;
          right: 0;
          display: block;
          width: 75%;
          height: 150px;
          background: #E84252;


        }

      }
      .otherImg2{
        position: relative;
        &::before{
          content: "";
          display: block;
          position: absolute;
          width: 80%;
          height: 180px;
          background: #E84252;
          z-index: -1;
          top: 50%;
          left: 50%;
          transform: translate( -50%,-50%);
        }
        img{
          &.gripImg01{
            transform: translateX(-50%);
            width: 75%;
          }
          &.gripImg02{
            transform: translateY(-50%);
            width: 55%;
            margin-top: 40px;
            margin-bottom: 100px;
            margin-left: auto;
          }
          &.gripImg01.left{
            animation: left .8s ease-in alternate forwards;
          }
          &.gripImg02.down{
            animation: down .8s ease-in alternate forwards;
          }
        }
      }
      .movie{
        width: 100%;
        margin: 0 auto 100px;
        position: relative;
        div{
          width: 90%;
          margin: 0 auto;
        }
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 60px;
          background: #E84252;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: -1;

        }
        video{
          border: 5px solid #000;
          display: block;
          width: 100%;
          box-sizing: border-box;
        }
      }

    }
    div.curater{
      width: 100%;
      position: relative;
      margin-bottom: 100px;
      &::before{
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product04/voice.jpg);
        background-size: cover;
        background-position: center center;
      }
      div.inner{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;
        div.box1{
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          h3{
            width: 67%;
          }
          &::before{
            content: "";
            display: block;

            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%,-50%);
            z-index: -2;

          }
        }
        div.box1.active{
          &::before{
            opacity:0.7;
            background: -webkit-linear-gradient(rgba(255,255,255,0) 0%, #E84252 0%);
            background: linear-gradient(rgba(255,255,255,0) 0%, #E84252 0%);
          background-repeat: no-repeat;
          background-size: 0%;
          -webkit-animation: drawTheLine 1s linear 1 forwards;
          animation: drawTheLine 1s linear 1 forwards;
          }
        }
        div.box2{
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;
        p{
          font-size: 14px;
          width: 90%;
          margin: 0 auto;
          color: #fff;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 8px #000;
          font-weight: 500;
          }
        }
      }
    }
    div.detail{
      margin-bottom: 100px;

      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #E84252;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }
        h3.down{
          animation: down 1s linear forwards;
        }
      }
      div.img{
        width: 85%;
        margin: 20px auto;
        img.detailImg{
          box-sizing: border-box;
          border: 10px solid #E84252;
          border-top: none;
        }
        p{
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }


    }
    div.collabo{
      margin-bottom: 100px;
      position: relative;
      &::before{
        content: "";
        display: block;
        background: url(../images/product/product04/collabo.jpg);
        background-size: cover;
        background-position: center center;
        padding-top: 100%;
      }
      div.inner{
        position: absolute;
        top: 31%;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        h3{
          width: 95%;
          margin-right: auto;
          padding-bottom: 10px;
        }
        p{
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.08rem;
          text-shadow: 2px 2px 8px #000;
          font-weight: 500;
          }
      }
    }
    div.other{
      margin-bottom: 100px;
      a{
        text-decoration: none;
      }
      div.title{
        position: relative;
        &::after{
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #E84252;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }
        h3{
          text-align: center;
          width: 90%;
          margin: auto;
          transform: translateY(-50%);
        }
        h3.down{
          animation: down 1s linear forwards;
        }
      }
      div.img{
              width: 85%;
              margin: 20px auto 100px;
              position: relative;

              img {
                box-sizing: border-box;
                border: 10px solid #E84252;
                border-top: none;
              }

              span.name {
                font-family: 'Teko';
                position: absolute;
                width: 70%;
                height: 20%;
                text-align: center;
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
                font-size: 40px;
                font-weight: bold;
                margin: auto;
                color: #ffffff9c;
              }
      }
    }
  }
  div.cheer{
    width: 90%;
    margin: 150px auto 200px;
    a{
      color: #000;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      align-items: center;
      justify-content: center;

      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }
      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }
      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }

      p{
        width :60%;
        box-sizing: border-box;
        padding-left: 20px;
        font-size: 12px;
        position: relative;
        font-weight: bold;
        letter-spacing: 0.05rem;
        &::before{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 0px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: top right;

        }
        &::after{
          content : "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: bottom right;

        }
      }
    }
  }

}


/*----------- PRODUCT05 coffee -----------*/
div#product05Container {
  width: 100%;
  overflow: hidden;

  div.main {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product05/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;

    h2 {
      width: 85%;
      margin: auto;
      position: absolute;
    top: 41%;
    left: 20px;
    }

    div.arrow {
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }

  div.content {
    div.outline {
      position: relative;

      div.img {
        box-sizing: border-box;
        border: #3BD0AE 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }

      div.explain {
        position: relative;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        margin-bottom: 150px;
        padding-bottom: 120px;

        div.outline_logo {
          width: 28%;
          transform: translateX(-50%);
        }

        div.outline_logo.left {
          animation: left 1.2s ease-in alternate forwards;
        }

        p {
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
      }

      div.point {
    position: absolute;
    bottom: -20%;
    right: 0%;
    width: 70%;

        path {
          stroke: #3BD0AE;
          stroke-width: 2px;
          stroke-dasharray: 3000;
          stroke-dashoffset: 3000;

        }

      }

      div.point.drawingText {
        path {
          -webkit-animation: DASH 3s ease-in alternate forwards;
          -o-animation: DASH 3s ease-in alternate forwards;
          animation: DASH 3s ease-in alternate forwards;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 40px;
        background: #3BD0AE;
        height: 0%;
        position: absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line-130 1.2s ease-in alternate 2s forwards;
      }
    }

    div.etc {
      position: relative;
      top: -130px;
      margin-bottom: -130px;

      .otherImg1 {
        margin-bottom: 120px;
        width: 100%;
        position: relative;

        img {
    padding: 0 5%;
    box-sizing: border-box;

        }

        &::after {
          content: "";
          position: absolute;
          bottom: 40%;
          z-index: -1;
          right: 0;
          display: block;
          width: 100%;
          height: 40px;
          background: #3BD0AE;
        }

      }

      .otherImg2 {
        position: relative;
        // &::before {
        //   content: "";
        //   display: block;
        //   position: absolute;
        //   width: 80%;
        //   height: 180px;
        //   background: #3BD0AE;
        //   z-index: -1;
        //   top: 50%;
        //   left: 50%;
        //   transform: translate(-50%, -50%);
        // }

        img {
          &.gripImg01 {
            transform: translateX(-100%);
            width: 75%;
            margin-left: -40px;
          }

          &.gripImg02 {
            transform: translateX(100%);
            width: 75%;
            margin-top: -30px;
            margin-bottom: 50px;
            margin-left: auto;
            margin-right: -40px;
            }

          &.gripImg01.left {
            animation: left .8s ease-out alternate forwards;
          }

          &.gripImg02.down {
            animation: down .8s ease-out alternate forwards;
          }
        }
      }

      .movie_box {
    margin: 0 5% 200px;
    box-sizing: border-box;
    border: solid 40px #3BD0AE;
    position: relative;
      }

      .movie {
        width: 100%;
        margin: 0 auto 100px;
        position: relative;

        img {
          width: 80%;
          margin-left: auto;
          border: solid 5px;
          position: absolute;
          top: -100px;
          right: 0;
        }
      }

      .movie_2 {
        width: 85%;
        position: relative;
        left: -40px;
        margin-bottom: 110px;
      }

      .otherImg3 {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        margin: auto;
            img {

          &.gripImg03 {
            transform: translateY(100%);
            margin: auto;
            margin-top: -60px;
          }

          &.gripImg03.down {
            animation: down .8s ease-out alternate forwards;
          }
          }
        }
    }

    div.curater {
      width: 100%;
      position: relative;
      margin-bottom: 100px;

      &::before {
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product05/voice.jpg);
        background-size: cover;
        background-position: left center;
      }

      div.inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;

        div.box1 {
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            width: 67%;
          }

          &::before {
            content: "";
            display: block;

            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -2;

          }
        }

        div.box1.active {
          &::before {
            opacity: 0.7;
            background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #3BD0AE 0%);
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, #3BD0AE 0%);
            background-repeat: no-repeat;
            background-size: 0%;
            -webkit-animation: drawTheLine 1s linear 1 forwards;
            animation: drawTheLine 1s linear 1 forwards;
          }
        }

        div.box2 {
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 14px;
            width: 90%;
            margin: 0 auto;
            color: #fff;
          }
        }
      }
    }

div.detail {
  margin-bottom: 100px;

  div.title {
    position: relative;

    &::after {
      content: "";
      display: block;
      width: 100%;
      height: 40px;
      background: #3BD0AE;
      position: absolute;
      bottom: -20px;
      z-index: -2;
    }

    h3 {
      text-align: center;
      width: 70%;
      margin: auto;
      transform: translateY(-50%);
    }

    h3.down {
      animation: down 1s linear forwards;
    }
  }

  div.slider-for {
    width: 85%;
    margin: 20px auto;

    img {
      box-sizing: border-box;
      border: 10px solid #3BD0AE;
      border-top: none;
    }

    p {
      font-size: 14px;
      text-align: left;
      margin-top: 10px;
    }
  }

  div.slider-nav {

    img {
      box-sizing: border-box;
      padding: 0 5px;
    }
  }

  .slick-list {
    position: relative;

    &::before {
      content: "";
      display: block;
      width: 100%;
      height: 30px;
      background: #3BD0AE;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

    div.collabo {
      margin-bottom: 100px;
      position: relative;

      &::before {
        content: "";
        display: block;
        background: url(../images/product/product05/collabo.jpg);
        background-size: cover;
        background-position: center center;
        padding-top: 100%;
      }

      div.inner {
        position: absolute;
        top: 45%;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);

        h3 {
          width: 95%;
          margin-right: auto;
          padding-bottom: 10px;
        }

        p {
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
        }
      }
    }

    div.other {
      margin-bottom: 100px;

      a {
        text-decoration: none;
      }

      div.title {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #3BD0AE;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }

        h3 {
          text-align: center;
          width: 90%;
          margin: auto;
          transform: translateY(-50%);
        }

        h3.down {
          animation: down 1s linear forwards;
        }
      }

      div.img {
        width: 85%;
        margin: 20px auto 0;

        img {
          box-sizing: border-box;
          border: 4px solid #3BD0AE;
          border-top: none;
        }
      }

      div.name {
        font-weight: bold;
        background: #3BD0AE;
        width: 85%;
        text-align: center;
        margin: 0px auto 20px;
        color: #fff;
        border-bottom: 4px solid #3BD0AE;
      }
    }
  }

  div.cheer {
    width: 90%;
    margin: 150px auto 200px;

    a {
      color: #000;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      align-items: center;
      justify-content: center;
      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }

      p {
    width: 60%;
    letter-spacing: 0.05rem;
    box-sizing: border-box;
    padding-right: 20px;
    font-size: 13px;
    position: relative;
    font-weight: bold;

        &::before {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 0px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: top right;

        }

        &::after {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: bottom right;

        }
      }
    }
  }

}


/*----------- PRODUCT06 glass -----------*/
div#product06Container {
  width: 100%;
  overflow: hidden;

  div.main {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product06/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;

    h2 {
      width: 76%;
      margin: auto;
      position: absolute;
    left: 20px;
    top: 12%;
    }

    div.arrow {
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }

  div.content {
    div.outline {
      position: relative;
    margin-bottom: 30px;
      div.img {
        box-sizing: border-box;
        border: #c2e403 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }

      div.explain {
        position: relative;
        z-index: 2;
        width: 100%;
        box-sizing: border-box;
        padding: 0 5% 0 10%;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        // margin-bottom: 150px;
        padding-bottom: 50px;

        div.outline_logo {
          width: 28%;
          transform: translateX(-50%);
        }

        div.outline_logo.left {
          animation: left 1.0s ease-out alternate forwards;
    }

        p {
          font-size: 14px;
          width: 72%;
          box-sizing: border-box;
          padding: 15px;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 40px;
        background: #c2e403;
        height: 0%;
        position: absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line-130 1.2s ease-in alternate 2s forwards;
      }
      }


      div.etc {
        position: relative;
        // margin: 0 0% 200px 5%;
        box-sizing: border-box;

        .otherImg1 {
          width: 100%;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            top: -15%;
            z-index: -1;
            right: 0;
            display: block;
            width: 95%;
            height: 100%;
            background: #c2e403;
          }
          img {
                &.gripImg00 {
                  transform: translate(15%, -20%);
                  width: 90%;
                }

                &.gripImg00.left0 {
                  animation: left .5s ease-out alternate forwards;
                  // position: relative;
                  // width: 95%;
                  // left: -5%;
                }
          }
        }

        div.point {
          position: relative;
          margin: auto;
          width: 90%;
          top: -20px;
          display: block;
          path {
            stroke: #c2e403;
            stroke-width: 2px;
            stroke-dasharray: 3000;
            stroke-dashoffset: 3000;
          }
        }
        div.point.drawingText {
          path {
            -webkit-animation: DASH 3s ease-in alternate forwards;
            -o-animation: DASH 3s ease-in alternate forwards;
            animation: DASH 3s ease-in alternate forwards;
          }
        }


        .video {
          width: 80%;
          margin: 20px auto 50px;
          left: 0;
          right: 0;
          display: block;
    border: solid 5px;
    }

    .slidebox {
      position: relative;
        .mypattern{
        img{
          box-sizing: border-box;
          padding: 10px;
        }
      }
      .mypattern .slick-slide:not(.slick-center) {
        -webkit-filter: blur(2px);
        -moz-filter:blur(2);
        -o-filter: blur(2);
        -ms-filter: blur(2);
        filter: blur(2);
        transition: 0.2s linear;
      }
        .background {
          background: #c2e403;
          height: 40px;
          width: 100%;
          position: absolute;
          top: 0;
          bottom: 0;
          margin: auto;
          z-index: -100;
        }
      }

      .otherImg2 {
        position: relative;
        width: 100%;
        margin: 60px auto 100px;

        img {
          &.gripImg02 {
            transform: translateX(-100%);
            width: 62%;
            padding: 100px 0 50px 8%;
          }

          &.gripImg02.down {
            animation: down .8s ease-out alternate forwards;
          }

          &.gripImg03 {
            transform: translateX(-100%);
            width: 62%;
            padding: 50px 0 100px 8%;
          }

          &.gripImg03.down {
            animation: down .8s ease-out alternate forwards;
          }

          &.gripImg04 {
            width: 15%;
            position: absolute;
            top: 2%;
            right: 8%;
            // transform: translateX(100%);
            transform:translate(15px, 15px);
          }

          &.gripImg04.down {
            animation: down .8s ease 5 alternate forwards;
            }
        }

        .background {
          background: #c2e403;
          width: 40%;
          position: absolute;
          top: 0%;
          right: 0;
          height: 100%;
          z-index: -100;
        }

      .edo_w {
        width: 15%;
        position: absolute;
        top: 0;
        right: 7%;
        bottom: 0;
        margin: auto;
      }
      }
    }

    div.curater {
      width: 100%;
      position: relative;
      margin-bottom: 100px;

      &::before {
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product06/voice.jpg);
        background-size: cover;
        background-position: left center;
      }

      div.inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;

        div.box1 {
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            width: 67%;
          }

          &::before {
            content: "";
            display: block;

            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -2;

          }
        }

        div.box1.active {
          &::before {
            opacity: 0.5;
            background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #c2e403 0%);
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, #c2e403 0%);
            background-repeat: no-repeat;
            background-size: 0%;
            -webkit-animation: drawTheLine 1s linear 1 forwards;
            animation: drawTheLine 1s linear 1 forwards;
          }
        }

        div.box2 {
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 14px;
            width: 90%;
            margin: 0 auto;
            color: #fff;
            text-shadow: 2px 2px 8px #000;
          }
        }
      }
    }

    div.detail {
      margin-bottom: 100px;

      div.title {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #c2e403;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }

        h3 {
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }

        h3.down {
          animation: down 1s linear forwards;
        }
      }

      div.slider-for {
        width: 85%;
        margin: 20px auto;

        img {
          box-sizing: border-box;
          border: 10px solid #c2e403;
          border-top: none;
        }

        p {
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }

      div.slider-nav {

        img {
          box-sizing: border-box;
          padding: 0 5px;
        }
      }

      .slick-list {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 30px;
          background: #c2e403;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    div.collabo {
      margin-bottom: 50px;
      position: relative;

      &::before {
        content: "";
        display: block;
        background: url(../images/product/product06/collabo.jpg);
        background-size: cover;
        background-position: center center;
        padding-top: 100%;
      }

      div.inner {
        position: absolute;
        bottom: 0;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);
        background: rgba(0, 0, 0, 0.3);
        padding: 13px 20px;

        h3 {
          width: 95%;
          margin-right: auto;
          padding-bottom: 10px;
        }

        p {
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
          letter-spacing: 0.08rem;
          font-weight: 500;
        }
      }
    }

       div.collabo_2 {
         margin-bottom: 100px;
         position: relative;

         &::before {
           content: "";
           display: block;
           background: url(../images/product/product06/collabo_2.jpg);
           background-size: cover;
           background-position: center center;
           padding-top: 100%;
         }
        }

    div.other {
      margin-bottom: 100px;

      a {
        text-decoration: none;
      }

      div.title {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #c2e403;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }

        h3 {
          text-align: center;
          width: 90%;
          margin: auto;
          transform: translateY(-50%);
        }

        h3.down {
          animation: down 1s linear forwards;
        }
      }

      div.img {
        width: 85%;
        margin: 20px auto 0;

        img {
          box-sizing: border-box;
          border: 4px solid #c2e403;
          border-top: none;
        }
      }

      div.name {
        font-weight: bold;
        background: #c2e403;
        width: 85%;
        text-align: center;
        margin: 0px auto 20px;
        color: #fff;
        border-bottom: 4px solid #c2e403;
      }
    }
  }





  div.cheer {
    width: 90%;
    margin: 150px auto 200px;

    a {
      color: #000;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      align-items: center;
      justify-content: center;

      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }

      p {
        width: 60%;
        letter-spacing: 0.05rem;
        box-sizing: border-box;
        padding-right: 20px;
        font-size: 13px;
        position: relative;
        font-weight: bold;

        &::before {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 0px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: top right;

        }

        &::after {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: bottom right;

        }
      }
    }
  }

}


/*----------- PRODUCT07 watch -----------*/
div#product07Container {
  width: 100%;
  overflow: hidden;

  div.main {
    width: 100%;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    box-sizing: border-box;
    padding: 60px 0 60px;
    background: url(../images/product/product07/main.jpg);
    background-position: center center;
    background-size: cover;
    position: relative;

    h2 {
      width: 85%;
      margin: auto;
      position: absolute;
      top: 12%;
      left: 20px;
    }

    div.arrow {
      position: absolute;
      bottom: 80px;
      left: 8%;
      width: 18px;
      z-index: 2;
    }
  }

  div.content {
    div.outline {
      position: relative;

      div.img {
        box-sizing: border-box;
        border: #9725EA 10px solid;
        border-left: none;
        border-right: none;
        position: relative;
        z-index: 2;
        margin-bottom: 30px;
      }

      div.explain {
    margin-bottom: 250px;
    padding-bottom: 130px;

        div.flex {
          position: relative;
          z-index: 2;
          width: 100%;
          box-sizing: border-box;
          padding: 0 5% 0 10%;
          display: flex;
          align-items: flex-start;
          justify-content: center;

          div.outline_logo {
            width: 28%;
            transform: translateX(-50%);
          }

          div.outline_logo.left {
            animation: left 1.2s ease-in alternate forwards;
          }

          p {
            font-size: 14px;
            width: 72%;
            box-sizing: border-box;
            padding: 15px;
          }
          }

          div.explainImg {
            position: absolute;
            z-index: 2;
            bottom: -20%;
            left: 5%;


            img {
              width: 70%;
              margin-left: auto;
              padding-right: 5%;
            }

            &:after {
              content: "";
              display: block;
              width: 0%;
              height: 40px;
              background: #9725EA;
              position: absolute;
              top: 50%;
              z-index: -1;
              transform: translateY(-50%);
              transition-delay: .5s;
              transition-duration: 1.7s;
              }
          }
          div.explainImg.active {
                        &:after {
                          content: "";
                          width: 100%;
                        }
          }
          }

      div.point {
        position: absolute;
        width: 90%;
        left: 5%;
        bottom: -26%;
        z-index: 2;
        path {
          stroke: #9725EA;
          stroke-width: 2px;
          stroke-dasharray: 3000;
          stroke-dashoffset: 3000;

        }

      }

      div.point.drawingText {
        path {
          -webkit-animation: DASH 3s ease-in alternate forwards;
          -o-animation: DASH 3s ease-in alternate forwards;
          animation: DASH 3s ease-in alternate forwards;
        }
      }

      &::before {
        content: "";
        display: block;
        width: 40px;
        background: #9725EA;
        height: 0%;
        position: absolute;
        left: 5%;
        top: -210px;
        animation: vertical-line-130 1.2s ease-in alternate 2s forwards;
      }
    }

    div.etc {
      position: relative;
      margin-bottom: 100px;

      .otherImg1 {
        margin: 50px auto 0 5%;
        width: 70%;
      }

      div.clock {
        width: 100%;
        height: 120px;
        position: relative;
        z-index: 2;
        margin-top: 50px;

        /* clock */
        div#clock_frame {
          position: relative;
          width: 100%;
          height: 80px;
          font-family: 'Press Start 2P', sans-serif;
          font-weight: bold;
          text-align: right;
          color: #9725EA;
          right: 5%;

          span {
            display: block;
            // float: right;
          }

          span#clock_date {
            font-size: 1rem;
            line-height: 1rem;
          }

          span#clock_time {
            font-size: 2.5rem;
          }
        }
        &:after {
          content: "";
          display: block;
          width: 0%;
          height: 40px;
          background: #9725EA;
          position: absolute;
          bottom: 0%;
          z-index: -1;
          transform: translateY(-50%);
          transition-delay: .5s;
          transition-duration: 1.7s;
        }
      }

      div.clock.active {
        &:after {
          content: "";
          width: 100%;
        }
      }


      .photo_2 {
        position: relative;
        width: 100%;
        margin-top: 30px;
        img {
          z-index: 1;
        }

        img.photo_A {
          position: relative;
          width: 50%;
          margin-left: auto;
          animation-name: move_1;
          animation-duration: 8s;
          animation-iteration-count: infinite;
        }

        @keyframes move_1 {

          0%,
          100% {
            // opacity: 0;
            transform: translate(0%, 100%);
          }
          25% {
            transform: translate(-100%, 100%);
          }
          50% {
            transform: translate(-100%, 0%);
          }
          75% {
            transform: translate(0%, 0%);
            // opacity: 1;
          }
        }

        img.photo_B {
          position: relative;
          width: 50%;
          animation-name: move_2;
          animation-duration: 8s;
          animation-iteration-count: infinite;
        }
        @keyframes move_2 {
          0%,
          100% {
            // opacity: 0;
            transform: translate(0%, -100%);
          }
          25% {
            transform: translate(100%, -100%);
          }
          50% {
            transform: translate(100%, 0%);
          }
          75% {
            transform: translate(0%, 0%);
            // opacity: 1;
          }
        }

        img.photo_C {
          position: absolute;
          width: 30%;
          top: 10%;
          left: 10%;
          animation-name: move_3;
          animation-duration: 8s;
          animation-iteration-count: infinite;
        }
        @keyframes move_3 {
          0%,
          100% {
            opacity: 0;
            transform: translate(165%, 0%);
          }
          25% {
            transform: translate(165%, 165%);
            opacity: 1;
          }
          50% {
            transform: translate(0%, 165%);
            opacity: 0;
          }
          75% {
            transform: translate(0%, 0%);
            opacity: 1;
          }
        }

        img.photo_D {
          position: absolute;
          width: 30%;
          bottom: 10%;
          right: 10%;
          animation-name: move_4;
          animation-duration: 8s;
          animation-iteration-count: infinite;
        }
        @keyframes move_4 {
          0%,
          100% {
            opacity: 0;
            transform: translate(-165%, 0%);
          }
          25% {
            transform: translate(-165%, -165%);
            opacity: 1;
          }
          50% {
            transform: translate(0%, -165%);
            opacity: 0;
          }
          75% {
            transform: translate(0%, 0%);
            opacity: 1;
          }
        }

        .background {
          position: absolute;
          background: #9725EA;
          width: 50%;
          height: 50%;
          left: 25%;
          top: 25%;
        }
      }

    }


    div.curater {
      width: 100%;
      position: relative;
      margin-bottom: 100px;

      &::before {
        content: "";
        display: block;
        padding-top: calc(560 / 375 *100%);
        background: url(../images/product/product07/voice.jpg);
        background-size: cover;
        background-position: center center;
      }

      div.inner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: 4px solid #fff;
        width: 90%;
        height: 90%;
        box-sizing: border-box;

        div.box1 {
          width: 100%;
          height: 50%;
          border-bottom: 2px solid #FFF;
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;

          h3 {
            width: 67%;
          }

          &::before {
            content: "";
            display: block;

            width: 85%;
            height: 77%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: -2;

          }
        }

        div.box1.active {
          &::before {
            opacity: 0.7;
            background: -webkit-linear-gradient(rgba(255, 255, 255, 0) 0%, #9725EA 0%);
            background: linear-gradient(rgba(255, 255, 255, 0) 0%, #9725EA 0%);
            background-repeat: no-repeat;
            background-size: 0%;
            -webkit-animation: drawTheLine 1s linear 1 forwards;
            animation: drawTheLine 1s linear 1 forwards;
          }
        }

        div.box2 {
          width: 100%;
          height: 50%;
          border-top: 2px solid #FFF;
          display: flex;
          align-items: center;
          justify-content: center;

          p {
            font-size: 14px;
            width: 90%;
            margin: 0 auto;
            color: #fff;
          }
        }
      }
    }

    div.detail {
      margin-bottom: 100px;

      div.title {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #9725EA;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }

        h3 {
          text-align: center;
          width: 70%;
          margin: auto;
          transform: translateY(-50%);
        }

        h3.down {
          animation: down 1s linear forwards;
        }
      }

      div.slider-for {
        width: 85%;
        margin: 20px auto;

        img {
          box-sizing: border-box;
          border: 10px solid #9725EA;
          border-top: none;
        }

        p {
          font-size: 14px;
          text-align: left;
          margin-top: 10px;
        }
      }

      div.slider-nav {

        img {
          box-sizing: border-box;
          padding: 0 5px;
        }
      }

      .slick-list {
        position: relative;

        &::before {
          content: "";
          display: block;
          width: 100%;
          height: 30px;
          background: #9725EA;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }

    div.collabo {
      margin-bottom: 100px;
      position: relative;

      &::before {
        content: "";
        display: block;
        background: url(../images/product/product07/collabo.jpg);
        background-size: cover;
        background-position: center center;
        padding-top: 100%;
      }

      div.inner {
        position: absolute;
        top: 5%;
        left: 50%;
        width: 90%;
        transform: translateX(-50%);

        h3 {
          width: 95%;
          margin-right: auto;
          padding-bottom: 10px;
        }

        p {
          width: 100%;
          border-top: 4px solid #fff;
          padding-top: 10px;
          font-size: 14px;
          color: #fff;
        }
      }
    }

    div.other {
      margin-bottom: 100px;

      a {
        text-decoration: none;
      }

      div.title {
        position: relative;

        &::after {
          content: "";
          display: block;
          width: 100%;
          height: 40px;
          background: #9725EA;
          position: absolute;
          bottom: -20px;
          z-index: -2;
        }

        h3 {
          text-align: center;
          width: 90%;
          margin: auto;
          transform: translateY(-50%);
        }

        h3.down {
          animation: down 1s linear forwards;
        }
      }

      div.img {
        width: 85%;
        margin: 20px auto 0;

        img {
          box-sizing: border-box;
          border: 4px solid #9725EA;
          border-top: none;
        }
      }

      div.name {
        font-weight: bold;
        background: #9725EA;
        width: 85%;
        text-align: center;
        margin: 0px auto 20px;
        color: #fff;
        border-bottom: 4px solid #9725EA;
      }
    }
  }

  div.cheer {
    width: 90%;
    margin: 150px auto 200px;

    a {
      color: #000;
      display: flex;
      flex-direction: row-reverse;
      text-decoration: none;
      align-items: center;
      justify-content: center;

      div.first {
        width: 35%;
        text-align: center;
        display: inline-block;
        position: relative;
        letter-spacing: 0.1rem;
        line-height: 1.3rem;
        font-weight: bold;
        padding: 10px 0px;
        box-sizing: border-box;
        text-decoration: none;
        background: #f5cd31;
        border-bottom: solid 5px #000;
        border-right: solid 5px #000;
      }

      div.first::before {
        content: " ";
        position: absolute;
        bottom: -8px;
        left: -1px;
        width: 0;
        height: 0;
        border-width: 0 8px 8px 0px;
        border-style: solid;
        border-color: transparent;
        border-bottom-color: #FFF;
      }

      div.first::after {
        content: " ";
        position: absolute;
        top: -1px;
        right: -8px;
        width: 0;
        height: 0;
        border-width: 0px 10px 10px 0px;
        border-style: solid;
        border-color: #FFF;
        border-bottom-color: transparent;
      }

      p {
        width: 60%;
        letter-spacing: 0.05rem;
        box-sizing: border-box;
        padding-right: 20px;
        font-size: 13px;
        position: relative;
        font-weight: bold;

        &::before {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          top: -10px;
          left: 0px;
          background: #000;
          transform: rotate(20deg);
          transform-origin: top right;

        }

        &::after {
          content: "";
          width: 95%;
          display: block;
          height: 5px;
          position: absolute;
          bottom: -10px;
          right: 10px;
          background: #000;
          transform: rotate(-20deg);
          transform-origin: bottom right;

        }
      }
    }
  }

}


/* ----------- SHOP INFO ------------ */
div#shopContainer{
  box-sizing: border-box;
  padding: 60px 0 120px;
  position: relative;
  height: 100%;
  &::before{
    content: "";
    display: block;
    width: 40px;
    background: #000;
    height: 0%;
    position :fixed;
    left: 5%;
    top: 0px;
    animation: vertical-line 1.2s ease-in alternate forwards;
  }
  h2{
    -webkit-writing-mode: vertical-rl;
    -ms-writing-mode: tb-rl;
    writing-mode: vertical-rl;
    font-size: 18px;
   left: 7%;
   top: 50%;
   transform: translateY(-50%);
    color: #fff;
    font-weight :bold;
    position: fixed;
    z-index: 9;
  }
  div.shop{
    padding: 25px 0;
    width: 62%;
    margin: 00px 0 0 26%;
    p.explain{
      font-size: 14px;
    }
    div.baseyard{
      text-align: center;
      margin: 25px 0;

      h3{
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 10px;
        span{
          padding-bottom: 1px;
          border-bottom: 1px solid #000;
          font-weight: bold;
        }
      }
      p.info{
        font-size: 14px;
        font-weight :bold;
      }
    }
    dl{
      margin-bottom: 15px;
      dt.btn{
        width: 100%;
        border: 2px solid #000;
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        padding: 5px 0;
        box-sizing: border-box;
        transition: 1s;
      }
      dd.access{
        width: 100%;
        font-size: 14px;
        box-sizing: border-box;
        padding: 10px;
        border: 2px solid #000;
        border-top: none;
      }
    }

    div.map{
      margin: 40px 0 60px;
    }
  }
  div.linkArea{
    ul{
      width: 80px;
      display: flex;
      margin: 15px auto 0;
      justify-content: space-between;
      li{
        width: 30px;
      }
    }

  }
}

div.iziModal{
  background: #000 !important;
  .inner{
    background: #000;
    position: relative;
  }
  p.title{
    color: #fff;
    font-size: 38px;
    text-align: center;
    margin-bottom: 25px;
    font-weight: bold;
  }
  div.close{
    color: #fff;
    font-size: 18px;
    text-align: right;
    font-weight: bold;
    box-sizing: border-box;
    padding-right: 5px;
  }
}

/* common */
  div.spacer{
    height: 60px;
    // position: relative;
    // z-index: 2;
    background: #fff;
    width: 100%;
  }
div.fixed_top{
  background: #fff;
  width: 100%;
  height: 60px;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: 5px solid #000;
  border-left: none;
  border-right: none;
  z-index: 3;
  ul{
    // width: 95%;
    // margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    li{
      a{
        display: inline-block;
        font-size: 15px;
        font-weight: bold;
        color: #000;
        text-decoration: none;
      }
      &:nth-child(2),&:nth-child(3){
        display: inline-block;
        &::before{
          content:"";
          display :inline-block;
          margin: 0 12px;
        }
      }
    }
  }
}
div.fixed_bottom{
  background: #fff;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  border: 5px solid #000;
  border-left: none;
  border-right: none;
  z-index: 3;
  h1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;

  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
  }
}
div.fixed_bottom02{
  background: #fff;
  width: 100%;
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  box-sizing: border-box;
  border: 5px solid #000;
  border-left: none;
  border-right: none;
  z-index: 9;
  transition: 1s;
  // transform: translateY(60px);
  // opacity: 0;
  h1{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;

  }
  a{
    width: 100%;
    height: 100%;
    display: flex;
    align-items:center;
    justify-content: center;
    font-size: 19px;
    font-weight: bold;
    text-decoration: none;
    color: #000;
  }
}
// div.fixed_bottom02.active{
//   animation: fixedBottom 1.0s ease-in alternate forwards;
// }
div.fixed_arrow{
position: fixed;
bottom: 90px;
right: 8%;
width: 20px;
transition: 1s;
z-index: 3;
}
div.fixed_arrowL{
  position: fixed;
  bottom: 70px;
  left: 8%;
  width: 20px;
  transition: 1s;
  z-index: 3;
}

.img-wrap {
  overflow: hidden;
  position: relative;
}

.img-wrap:before,
.img-wrap:after {
  animation: 1s cubic-bezier(.4, 0, .2, 1) 0.7s both;
  background: #fff;
  content: '';
  left: 0;
  pointer-events: none;
  position: absolute;
  right: 0;
  z-index: 1;
}
.img-wrap:before {
  animation-name: img-wrap-before;
  top: 0;
  bottom: 50%;
}

.img-wrap:after {
  animation-name: img-wrap-after;
  top: 50%;
  bottom: 0;
}
#product01Container{
  .img-wrap:before,
  .img-wrap:after {
    background: #4BB953;

  }
}
#product02Container{
  .img-wrap:before,
  .img-wrap:after {
    background: #F5CD31;

  }
}
#product03Container{
  .img-wrap:before,
  .img-wrap:after {
    background: #0099D1;

  }
}
#product04Container{
  .img-wrap:before,
  .img-wrap:after {
    background: #E84252;

  }
}
#product05Container {
  .img-wrap:before,
  .img-wrap:after {
    background: #3BD0AE;
  }
}
#product06Container {
  .img-wrap:before,
  .img-wrap:after {
    background: #c2e403;
  }
}
#product07Container {
  .img-wrap:before,
  .img-wrap:after {
    background: #9725EA;
  }
}

/* ANIMATION
--------------------------------------*/
@include anime-drawTheLine;
@keyframes vertical-line {
  100% {
    height: 100%;
  }
}
@keyframes vertical-line-high {
  100% {
    height: calc(100% + 150px);
  }
}
@keyframes vertical-line-120 {
  100% {
    height: 120%;
  }
}
@keyframes vertical-line-130 {
  100% {
    height: 130%;
  }
}
@keyframes vertical-line-160 {
  100% {
    height: 160%;
  }
}
@-webkit-keyframes DASH{
  0%{stroke-dashoffset:3000;}
  100%{stroke-dashoffset:0;}
}
@keyframes DASH{
  0%{stroke-dashoffset:3000;}
  100%{stroke-dashoffset:0;}
}
@keyframes text-scroll01{
  0%{transform: translate(0%,-50%)}
  100%{transform: translate(-45%,-50%)}
}
@keyframes text-scroll02{
  0%{transform: translate(0%,-25%)}
  100%{transform: translate(-60%,-25%)}
}
@keyframes fixedBottom{
  0%{opacity:0}
  100%{opacity:1}
}

@keyframes img-wrap-before {
  100% {
    transform: translateX(100%);
  }
}

@keyframes img-wrap-after {
  100% {
    transform: translateX(-100%);
  }
}
@keyframes box-width {
  100% {
    width: 85%;
  }
}
@keyframes scale03 {
  100% {
    transform: translateY(-50%) scale(1);
  }
}
@keyframes down {
  100% {
    transform: translateY(0%);
  }
}
@keyframes left {
  100% {
    transform: translateX(0%);
  }
}

/* IMPORT
--------------------------------------*/
@import 'slick';
@import 'slick-theme';
@import 'iziModal';
