
/* メディアクエリー用 */
@mixin mq($breakpoint: sp) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
/* webfont設定 */
@mixin font-face($family, $path, $weight: normal, $style: normal) {
  @font-face {
    font-family: $family;
    src: url('#{$path}.woff2') format('woff2'),
					url('#{$path}.woff') format('woff'),
					url('#{$path}.ttf') format('truetype');
    font-weight: $weight;
    font-style: $style;
  }
}
/* CLEARFIX */
@mixin clearfix {
	*zoom: 1;
	&::after {
	content: '';
	display: table;
	clear: both;
	}
}

/* 100vhフィット(主にiOSにて) */
@mixin cover-fit{
	height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
}

/* ホバー時 */
@mixin link-color($color) {
		color: $color; 
		&:hover, &:active, &:focus {
			color: lighten($color, 20%);
		}
}
@mixin link-bg-color($color) {
	background: $color;
	transition: 1s;
	&:hover, &:active, &:focus {
	background: lighten($color, 20%);
	}
}
@mixin link-border-color($color) {
		background: #fff;
		transition: 1s;
		border: 1px solid $color;
		&:hover, &:active, &:focus {
		background: $color;
		color: #fff;
	}
}

/* 蛍光マーカーを引いたような線 */
@mixin marker($color){
	background: -webkit-linear-gradient(rgba(255,255,255,0) 50%, $color 0%);
	background: linear-gradient(rgba(255,255,255,0) 50%, $color 0%);
}

/* 中央配置 */
@mixin position-center{
	position: absolute;
	top: 0;
	left: 0;
	transform: translate(-50%,-50%);
}

/* 画像をモノクロにする */
@mixin monoqlo{
	.monoqlo{
		-webkit-filter: grayscale(100%);
		filter: grayscale(100%);
	}
}

/* ANIMATION
--------------------------------------*/

/* カバー画像をスクロール共にフェードさせる */
@mixin anime-cover-fade{
	&.cover-fade{
		width: 100%;
		height: 100%;
		overflow: hidden;
		opacity: 1;
		-webkit-transform: scale(1);
		transform: scale(1);
		-webkit-transition: opacity 1s, -webkit-transform 1s;
		transition: opacity 1s, -webkit-transform 1s;
		transition: transform 1s, opacity 1s;
		transition: transform 1s, opacity 1s, -webkit-transform 1s;
		@include mq(pc){
			background: url(../images/main_pc.jpg) no-repeat;
			background-position: left top;
			background-size: cover;
			display: flex;
			flex-direction: row-reverse;
		}
	}
	&.end{
		opacity: 0;
		-webkit-transform: scale(1.1);
		transform: scale(1.1);
		-webkit-transition: opacity 1s, -webkit-transform 1s;
		transition: opacity 1s, -webkit-transform 1s;
		transition: transform 1s, opacity 1s;
		transition: transform 1s, opacity 1s, -webkit-transform 1s;
	}
	&.z-index{
		z-index: -1;
	}
}

/* 点滅アニメーション */
@mixin anime-blink{
	.blink {
		animation: blink 2s ease 0s infinite normal;
		-webkit-animation: blink 2s ease 0s infinite normal ;
	}
	@keyframes blink {
		0% { opacity: 0 }
		
		100% { opacity: 1 }
	}
	@-webkit-keyframes blink {
		0% { opacity: 0 }

		100% { opacity: 1 }
	}
}

/* ふわっとするアニメーション */
@mixin anime-fuwat{
	.fuwatAnime {
	-webkit-animation-fill-mode: both;
	-ms-animation-fill-mode: both;
	animation-fill-mode: both;
	-webkit-animation-duration: 1.5s;
	-ms-animation-duration: 1.5s;
	animation-duration: 1.5s;
	-webkit-animation-name: fuwatAnime;
	-ms-animation-name: fuwatAnime;
	animation-name: fuwatAnime;
	visibility: visible !important;
	}
	@-webkit-keyframes fuwatAnime {
		0% { opacity: 0;-webkit-transform: translateY(20px);}
		100% { opacity: 1;-webkit-transform: translateY(0); }
	}
	@keyframes fuwatAnime {
		0% { opacity: 0;-webkit-transform: translateY(20px);-ms-transform: translateY(20px);transform: translateY(20px);}
		100% { opacity: 1;-webkit-transform: translateY(0);-ms-transform: translateY(0);transform: translateY(0);}
	}
}

/* 画像をホバーすると大きくなる */
@mixin imgZoom {
	-webkit-transform: scale(1);
	transform: scale(1);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
@mixin imgZoomHover{
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
}
/* SVGアニメーション(文字を書いてるような感じ) */
@mixin anime-svg($color){
	.drawingText{    
			stroke:$color;
			fill:$color;
			stroke-width:1;
			stroke-dasharray: 3000;
			stroke-dashoffset:3000;
			animation: DASH 2s ease-in alternate forwards;
			-webkit-animation:DASH 2s ease-in alternate forwards;
			-o-animation:DASH 2s ease-in alternate forwards;
			animation:DASH 2s ease-in alternate forwards;
	}
	@-webkit-keyframes DASH{
		0%{stroke-dashoffset:3000;fill-opacity: 0;}
		80%{stroke-dashoffset:0;fill-opacity: 0;}
		100%{fill-opacity: 1;}
	}
	@keyframes DASH{
		0%{stroke-dashoffset:3000;fill-opacity: 0;}
		80%{stroke-dashoffset:0;fill-opacity: 0;}
		100%{fill-opacity: 1;}
	}
}

/* ローディング用アニメーション */
@mixin loading{
	#loader-bg {
		position: fixed;
		width: 100%;
		height: 100vh;
		top: 0px;
		left: 0px;
		background: #FFF;
		z-index: 1;
		z-index: 99999;
		
		#loading{
			display: flex;
			align-items: center;
			justify-content: center;
			@include mq(pc){
				width: 100%;
				margin: auto;
				height:100%
			}
			@include mq(sp){
				width: 100%;
				margin: auto;
				height: 100%;
			}
			div{
				@include mq(pc){
					width: 20%;
					margin: auto;
				}
				@include mq(sp){
					width: 40%;
					margin: auto;
				}
			}
		}
	}
}


/* 線を引くアニメーション */
@mixin anime-drawTheLine{
	@-webkit-keyframes  drawTheLine{
		from{
			background-size: 0% 100%;
		}
		100%{
			background-size: 100% 100%;
		}
	}

	@keyframes  drawTheLine{
		from{
			background-size: 0% 100%;
			-ms-background-size: 0% 100%;
		}
		100%{
		background-size: 100% 100%;
		-ms-background-size: 100% 100%;
		}
	}

}


/* 画像を徐々に出すアニメーション */
// 左からアニメーション
@mixin slide-in-img-L{
	.img-wrapL {
		animation: img-opacity 2s cubic-bezier(.4, 0, .2, 1) forwards;
		overflow: hidden;
		position: relative;
	}
	
	.img-wrapL:before {
		animation: img-wrapL 2s cubic-bezier(.4, 0, .2, 1) forwards;
		background: #fff;
		bottom: 0;
		content: '';
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
	@keyframes img-opacity {
		100% {
			opacity: 1.0;
		}
	}
	@keyframes img-wrapL {
		100% {
			transform: translateX(100%);
		}
	}
}
// 右からアニメーション
@mixin slide-in-img-R{
	.img-wrapR {
		overflow: hidden;
		position: relative;
		animation: img-opacity 2s cubic-bezier(.4, 0, .2, 1) forwards;
	}
	
	.img-wrapR:before {
		animation: img-wrapR 2s cubic-bezier(.4, 0, .2, 1) forwards;
		background: #fff;
		bottom: 0;
		content: '';
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
	@keyframes img-opacity {
		100% {
			opacity: 1.0;
		}
	}
	@keyframes img-wrapR {
		100% {
			transform: translateX(-100%);
		}
	}
}
// 上からアニメーション
@mixin slide-in-img-B{
	.img-wrapB {
		overflow: hidden;
		position: relative;
		animation: img-opacity 2s cubic-bezier(.4, 0, .2, 1) forwards;
	}
	
	.img-wrapB:before {
		animation: img-wrapB 2s cubic-bezier(.4, 0, .2, 1) forwards;
		background: #fff;
		bottom: 0;
		content: '';
		left: 0;
		pointer-events: none;
		position: absolute;
		right: 0;
		top: 0;
		z-index: 1;
	}
	@keyframes img-opacity {
		100% {
			opacity: 1.0;
		}
	}
	@keyframes img-wrapB {
		100% {
			transform: translateY(100%);
		}
	}
}
